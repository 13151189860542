function setCookie(key, value, expiry = 30) {
	var expires = new Date();
	expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
	// document.cookie = `${key}=${value};expires=${expires.toUTCString()};SameSite=None;secure;path=/;`;
	document.cookie = `${key}=${value};expires=${expires.toUTCString()};path=/;`;
}

function getCookie(key) {
	var value = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
	if (value) {
		if (value[2][0] == '{' || value[2][0] == '[') {
			value[2] = JSON.parse(value[2]);
		}
		return value[2];
	}
}

function eraseCookie(key) {
	let value = JSON.stringify(getCookie(key));
	setCookie(key, value, -1);
}

// get session OR local storage
function getStorage(key, type = 'session') {
	var value = '';
	switch (type) {
		case 'session':
			value = sessionStorage.getItem(key);
			break;
		case 'local':
			value = localStorage.getItem(key);
			break;
	}
	if (value) {
		if (value[0] == '{' || value[0] == '[') value = JSON.parse(value);
	}

	return value;
}

// set session OR local storage
function setStorage(key, value, type = 'session') {
	if (typeof value === 'object') value = JSON.stringify(value);
	switch (type) {
		case 'session':
			sessionStorage.setItem(key, value);

			break;
		case 'local':
			localStorage.setItem(key, value);
			break;
	}
	return value;
}

function getQueryParams() {
	return new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});
}

function isPrivateIP(ip) {
	var parts = ip.split('.');
	return (
		parts[0] === '10' ||
		(parts[0] === '172' && parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31) ||
		(parts[0] === '192' && parts[1] === '168') ||
		(parts[0] === '127' && parts[1] === '0' && parts[2] === '0')
	);
}

function parseJwt(token = getStorage('ss_token', 'local')) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

// standardized API request
function apiRequest(uri = '', method = 'GET', _data = [], dataType = 'JSON', async = true) {
	if (uri == '') return false;

	return $.ajax({
		url: `${uri}`,
		type: method,
		data: _data,
		dataType,
		async,
		crossDomain: true,
		xhrFields: {
			withCredentials: true,
		},
		headers: {
			// Authorization: "Bearer " + window.sessionStorage.getItem("ss_token"),
			// "X-HTTP-Method-Override": method.toUpperCase(),
			// "X-TA-SESSION": localStorage.session_id,
			// "X-TA-ACCOUNT": localStorage.account_id,
			// "X-TA-C": localStorage.c_id,
			// "X-TA-META": localStorage.meta,
		},
	});
}

var resp = $.ajax({
	url: '/api/event/session',
	type: 'POST',
	// data: $('form[name="login"]').serialize(),
	// dataType: 'JSON',
	async: false,
});

$('#ss-btn-login-did, #ss-btn-login-resend').on('click', () => {
	var did = $('#did').val();
	if (did.length == 10) did = `1${did}`;
	did = did.replace(/\D/g, ''); // replace all non numeric
	// console.log(did);
	var resp = $.ajax({
		url: '/api/login/' + did,
		type: 'POST',
		data: $('form[name="login"]').serialize(),
		// dataType: 'JSON',
		async: true,
	});

	$('#ss-otp').removeClass('d-none');
	$('#ss-btn-login-did').addClass('d-none');
	$('#delete-num').addClass('d-none');
});

$('#ss-btn-login-code').on('click', (e) => {
	e.preventDefault();
	var did = $('#did').val();
	if (did.length == 10) did = `1${did}`;
	did = did.replace(/\D/g, ''); // replace all non numerics
	$.ajax({
		url: '/api/login/' + did,
		type: 'PUT',
		data: $('form[name="login"]').serialize(),
		// dataType: 'JSON',
		async: true,
	})
		.done((data) => {
			// console.log(data);
			window.location.href = '/profile';
		})
		.fail((err) => {
			// console.log('err');
			console.log(err);
		});
});

$('#ss-btn-logout').on('click', () => {
	document.cookie = 'ss_token=; Max-Age=-1;';
	sessionStorage.clear();
	window.location.replace('/logout');
});

$(' #ss-btn-login-did-claim, #ss-btn-login-resend-claim').on('click', () => {
	var did = $('#did-claim').val();
	if (did.length == 10) did = `1${did}`;
	did = did.replace(/\D/g, ''); // replace all non numeric
	// console.log(did);
	var resp = $.ajax({
		url: '/api/login/' + did,
		type: 'POST',
		data: $('form[name="claim"]').serialize(),
		// dataType: 'JSON',
		async: true,
	});

	$('#ss-otp-claim').removeClass('d-none');
	$('#ss-btn-login-did-claim').addClass('d-none');
});

$('#ss-btn-login-code-claim').on('click', (e) => {
	e.preventDefault();
	var did = $('#did-claim').val();
	if (did.length == 10) did = `1${did}`;
	did = did.replace(/\D/g, ''); // replace all non numerics
	$.ajax({
		url: '/api/login/' + did,
		type: 'PUT',
		data: $('form[name="claim"]').serialize(),
		// dataType: 'JSON',
		async: true,
	})
		.done((data) => {
			// console.log(data);
			window.location.href = '/profile';
		})
		.fail((err) => {
			// console.log('err');
			console.log(err);
		});
});

$('.ss-showMore-link').on('click', () => {
	$('#showmore').toggleClass('show');
	if ($('#showmore').hasClass('show')) {
		$('.ss-showMore-link').html('show less...');
	} else {
		$('.ss-showMore-link').html('show more...');
	}
});

// copy profile button
$('#ss-send-profile-code').on('click', () => {
	var data = { copy: $('#account_copy').val() };
	var resp = $.ajax({
		url: `/profile/copy`,
		type: 'POST',
		data: data,
		// dataType: 'JSON',
		async: true,
	});
	// console.log(resp);

	$('#ss-send-profile-code').html('Resend Verification Code');
	$('#ss-copy-verification').removeClass('d-none');
});

$('#ss-copy-profile-submit').on('click', (e) => {
	e.preventDefault();

	var data = { copy: $('#account_copy').val(), code: $('#account_code').val() };
	var resp = $.ajax({
		url: `/profile/copy`,
		type: 'PUT',
		data: data,
		// dataType: 'JSON',
		async: true,
	});
	// console.log(resp);
});

// // delete account button
// $('#ss-delete-account').on('click', () => {
// 	var data = {};
// 	var resp = $.ajax({
// 		url: `/profile/delete`,
// 		type: 'DELETE',
// 		data: data,
// 		// dataType: 'JSON',
// 		async: true,
// 	});
// 	// console.log(resp);

// 	$('#ss-delete-account').html('Resend Verification Code').removeClass('btn-danger').addClass('btn-primary');
// 	$('#ss-delete-form').removeClass('d-none');
// });

// //delete-from-cancel
// $('#ss-delete-form-cancel').on('click', () => {
// 	$('#ss-delete-account').html('Delete Profile').removeClass('btn-primary').addClass('btn-danger');
// 	$('#ss-delete-form').addClass('d-none');
// });

// //delete form submit
// $('#ss-delete-submit').on('click', (e) => {
// 	e.preventDefault();

// 	var data = { code: $('#delete_code').val() };
// 	var resp = $.ajax({
// 		url: `/profile/delete`,
// 		type: 'DELETE',
// 		data: data,
// 		async: true,
// 	});
// 	// console.log(resp);
// });


$('#ss-btn-delete-did, #ss-btn-delete-resend').on('click', () => {
	var did = $('#did').val();
	if (did.length == 10) did = `1${did}`;
	did = did.replace(/\D/g, ''); // replace all non numeric
	// console.log(did);
	var resp = $.ajax({
		url: '/api/delete/' + did,
		type: 'POST',
		data: $('form[name="delete"]').serialize(),
		// dataType: 'JSON',
		async: true,
	});

	$('#ss-otp-delete').removeClass('d-none');
	$('#ss-btn-delete-did').addClass('d-none');
});

$('#ss-btn-delete-code').on('click', (e) => {
	e.preventDefault();
	var did = $('#did').val();
	if (did.length == 10) did = `1${did}`;
	did = did.replace(/\D/g, ''); // replace all non numerics
	$.ajax({
		url: '/api/delete/' + did,
		type: 'PUT',
		data: $('form[name="delete"]').serialize(),
		// dataType: 'JSON',
		async: true,
	})
		.done((data) => {
			// console.log(data);
			window.location.href = '/profile';
		})
		.fail((err) => {
			// console.log('err');
			console.log(err);
		});
});



//verifiy email
$('#ss-btn-verify-email').on('click', (e) => {
	//e.preventDefault();
	if ($('#verify_email').val() == 'false') {
		$('#verify_email').val('true');
	} else {
		$('#verify_email').val('false');
	}
});

// Plan upgrade
$('#plan-upgrade-button').on('click', () => {
	$('#current-plan').addClass('d-none');
	$('#plans').removeClass('d-none');
});

// Plan upgrade cancel
$('#plan-back-button').on('click', () => {
	$('#current-plan').removeClass('d-none');
	$('#plans').addClass('d-none');
});

// payment from
$('#add-new-card-button').on('click', () => {
	$('#payment-methods').addClass('d-none');
	$('#payment-form').removeClass('d-none');
});

// Payment form cancel
$('#payment-back-button').on('click', () => {
	$('#payment-methods').removeClass('d-none');
	$('#payment-form').addClass('d-none');
});

// Invoice

$('#invoice-button').on('click', () => {
	$('#invoices').addClass('d-none');
	$('#invoice').removeClass('d-none');
});

$('#invoice-back-button').on('click', () => {
	$('#invoices').removeClass('d-none');
	$('#invoice').addClass('d-none');
});

// ***************************
//RECORD MODULE

$('#record-button').on('click', () => {
	$('#record-button').addClass('d-none');
	$('#stop-record-button').removeClass('d-none');

	$('#record-text').addClass('d-none');
	$('#stop-record-text').removeClass('d-none');
});

$('#stop-record-button').on('click', () => {
	$('#stop-record-button').addClass('d-none');
	$('#play-record-button').removeClass('d-none');

	$('#stop-record-text').addClass('d-none');
	$('#play-record-text').removeClass('d-none');
});

$('#play-record-button').on('click', () => {
	$('#play-record-button').addClass('d-none');
	$('#stop-record-button').removeClass('d-none');
});

$('#clear-record-button').on('click', () => {
	$('#play-record-button').addClass('d-none');
	$('#stop-record-button').addClass('d-none');
	$('#record-button').removeClass('d-none');

	$('#stop-record-text').addClass('d-none');
	$('#play-record-text').addClass('d-none');

	$('#record-text').removeClass('d-none');
});

// Record spam greeting

$('#spam-record-button').on('click', () => {
	$('#spam-record-button').addClass('d-none');
	$('#spam-stop-record-button').removeClass('d-none');

	$('#spam-record-text').addClass('d-none');
	$('#spam-stop-record-text').removeClass('d-none');
});

$('#spam-stop-record-button').on('click', () => {
	$('#spam-stop-record-button').addClass('d-none');
	$('#spam-play-record-button').removeClass('d-none');

	$('#spam-stop-record-text').addClass('d-none');
	$('#spam-play-record-text').removeClass('d-none');
});

$('#spam-play-record-button').on('click', () => {
	$('#spam-play-record-button').addClass('d-none');
	$('#spam-stop-record-button').removeClass('d-none');
});

$('#spam-clear-record-button').on('click', () => {
	$('#spam-play-record-button').addClass('d-none');
	$('#spam-stop-record-button').addClass('d-none');
	$('#spam-record-button').removeClass('d-none');

	$('#spam-stop-record-text').addClass('d-none');
	$('#spam-play-record-text').addClass('d-none');

	$('#spam-record-text').removeClass('d-none');
});

function mask_did(value, type = 'phone11') {
	var new_value = value;
	switch (type) {
		case 'phone10':
			new_value = `(${new_value.substr(1, 3)}) ${new_value.substr(4, 3)}-${new_value.substr(7, 4)}`;
			break;
		case 'phone11':
			new_value = `+1 (${new_value.substr(1, 3)}) ${new_value.substr(4, 3)}-${new_value.substr(7, 4)}`;
			break;
	}

	return new_value;
}
